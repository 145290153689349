import { useEffect } from 'react'
import { clearJwtTokens, login } from 'api/jwtApi'
import { useNavigate } from 'react-router'

export function Logout() {
  const navigate = useNavigate()
  useEffect(() => {
    clearJwtTokens()
    navigate('../login', { replace: true })
		window.location.reload()
  }, [])

  return <></>
}

export default Logout
