import { sendAuthorizedRequest } from './jwtApi'
import { BASE_URL } from 'api/config'
import { ApiResponse, Workplace, WorkplaceConfig } from 'types'
import { Dayjs } from 'dayjs'

export async function getWorkplaces(): Promise<ApiResponse<Array<Workplace>>> {
  let workplaces_endpoint_url = BASE_URL + 'booking/workplaces/'

  let result = await sendAuthorizedRequest(workplaces_endpoint_url)

  return {
    status_code: result.status,
    ok: result.ok,
    body: await result.json(),
  }
}

export async function getWorkplaceConfig(
  date: Dayjs
): Promise<ApiResponse<WorkplaceConfig>> {
  let workplacesEndpointUrl =
    BASE_URL +
    `booking/workplace_config/active/?date=${date.format('YYYY-MM-DD')}`

  let result = await sendAuthorizedRequest(workplacesEndpointUrl)

  return {
    status_code: result.status,
    ok: result.ok,
    body: await result.json(),
  }
}


