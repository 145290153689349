import {
	Stack,
	Step,
	StepLabel,
	Stepper,
	TextField,
	Typography,
} from '@mui/material'
import { Box, Container } from '@mui/system'
import LoadingButton from '@mui/lab/LoadingButton'
import { useState } from 'react'
import { UserRegistrationData } from 'types'
import {
	changePassword,
	checkPasswordResetToken,
	generatePasswordResetToken,
	registerUser,
	resetPassword,
} from 'api/userApi'
import { useMutation, useQuery } from 'react-query'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'

const steps = ['1', '2', '3']

export const ResetPasswordForm = () => {
	const [userPasswordResetData, setUserPasswordResetData] = useState({
		email: '',
		confirmation_token: '',
		password: '',
	})

	const [activeStep, setActiveStep] = useState(0)

	const [repeatPassword, setRepeatPassword] = useState<string>('')

	const { enqueueSnackbar } = useSnackbar()

	const navigate = useNavigate()

	const handleRepeatPasswordChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRepeatPassword(event.target.value)
	}

	const handleUserResetPasswordData = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		setUserPasswordResetData((prev) => ({
			...prev,
			[event.target.name]: event.target.value,
		}))
	}

	const validatePassword = () => {
		if (repeatPassword !== userPasswordResetData.password)
			throw 'Пароли не совпадают'
	}

	const handlePasswordResetTokenCheck = async () => {
		let result = await checkPasswordResetToken(
			userPasswordResetData.email,
			userPasswordResetData.confirmation_token
		)

		if (!result.ok) {
			if (result.status_code === 400) {
				throw 'Неверный код подтверждения.'
			}

			if (result.status_code === 400) {
				throw 'Пользователь с такой эл. почтой не существует.'
			}

			let error = result.body as any

			for (let key in error) {
				if (!Array.isArray(error[key])) continue

				error[key] = error[key].map((x: string) => {
					let errorString = `${key}: ${x}`
						.replace('user', 'пользователь')
						.replace('patronymic', 'Отчество')
						.replace('last_name', 'Фамилия')
						.replace('first_name', 'Имя')
						.replace('phone number', 'номер телефона')
						.replace('phone_number', 'Номер телефона')

					return errorString
				})
			}

			if (Object.values(error).length > 0) throw Object.values(error)[0]
		}
	}

	const handleGeneratingPasswordResetToken = async () => {
		let result = await generatePasswordResetToken(userPasswordResetData.email)

		if (!result.ok) {
			let error = result.body as any

			if (result.status_code === 404) {
				throw 'Пользователь с такой эл. почтой не существует.'
			}

			for (let key in error) {
				if (!Array.isArray(error[key])) continue

				error[key] = error[key].map((x: string) => {
					let errorString = `${key}: ${x}`.replace('user', 'пользователь')

					return errorString
				})
			}

			if (Object.values(error).length > 0) throw Object.values(error)[0]
		}
	}

	const handlePasswordReset = async () => {
		validatePassword()

		let result = await resetPassword({ ...userPasswordResetData })

		if (!result.ok) {
			let error = result.body as any

			for (let key in error) {
				if (!Array.isArray(error[key])) continue

				error[key] = error[key].map((x: string) => {
					let errorString = `${key}: ${x}`
						.replace('user', 'пользователь')
						.replace('password', 'пароль')
						.replace('confirmation_token', 'код подтверждения')

					return errorString
				})
			}

			if (Object.values(error).length > 0) throw Object.values(error)[0]
		}
	}

	const handleSubmitButtonClick = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		e.stopPropagation()

		if (activeStep === 0) {
			generatePasswordResetTokenQuery.refetch()
		} else if (activeStep === 1) {
			checkPasswordResetTokenQuery.refetch()
		} else if (activeStep === 2) {
			passwordResetQuery.refetch()
		}
	}

	const generatePasswordResetTokenQuery = useQuery(
		'passwordResetToken',
		handleGeneratingPasswordResetToken,
		{
			enabled: false,
			retry: 2,
			onSuccess: () => {
				setActiveStep(activeStep + 1)
			},
			onError: (error: string) => {
				enqueueSnackbar(error, { variant: 'error' })
			},
		}
	)

	const checkPasswordResetTokenQuery = useQuery(
		'passwordResetTokenCheck',
		handlePasswordResetTokenCheck,
		{
			enabled: false,
			retry: 2,
			onSuccess: () => {
				setActiveStep(activeStep + 1)
			},
			onError: (error: string) => {
				enqueueSnackbar(error, { variant: 'error' })
			},
		}
	)

	const passwordResetQuery = useQuery('passwordReset', handlePasswordReset, {
		enabled: false,
		retry: 2,
		onSuccess: () => {
			enqueueSnackbar('Пароль успешно изменен', {variant: 'success'})
			setTimeout(() => navigate('/login', {replace: true}), 2000)
		},
		onError: (error: string) => {
			enqueueSnackbar(error, { variant: 'error' })
		},
	})

	return (
		<Box>
			<Container
				maxWidth='sm'
				sx={{
					height: '100%',
					paddingTop: '10%',
				}}
			>
				<Stack
					spacing={1}
					sx={{
						height: 'fit-content',
						width: '100%',
					}}
					component='form'
					onSubmit={handleSubmitButtonClick}
				>
					<Typography variant='h5'>Изменение пароля</Typography>
					<Stepper activeStep={activeStep}>
						{steps.map((label, index) => {
							return (
								<Step key={label}>
									<StepLabel></StepLabel>
								</Step>
							)
						})}
					</Stepper>
					<TextField
						required
						label='Email'
						name='email'
						onChange={handleUserResetPasswordData}
						disabled={activeStep !== 0}
						fullWidth
						value={userPasswordResetData.email}
					/>
					{activeStep === 1 && (
						<TextField
							required
							label='Код подтверждения'
							name='confirmation_token'
							onChange={handleUserResetPasswordData}
							fullWidth
							helperText={
								activeStep === 1
									? 'Введите код из письма, пришедшего вам на почту.'
									: ''
							}
							value={userPasswordResetData.confirmation_token}
						/>
					)}
					{activeStep === 2 && (
						<TextField
							required
							label='Новый пароль'
							name='password'
							onChange={handleUserResetPasswordData}
							fullWidth
							value={userPasswordResetData.password}
						/>
					)}
					{activeStep === 2 && (
						<TextField
							required
							label='Новый пароль (повторно)'
							fullWidth
							onChange={handleRepeatPasswordChange}
							value={repeatPassword}
						/>
					)}
					<LoadingButton
						variant='outlined'
						fullWidth
						type='submit'
						loading={
							passwordResetQuery.isLoading ||
							generatePasswordResetTokenQuery.isLoading ||
							checkPasswordResetTokenQuery.isLoading
						}
					>
						Поменять пароль
					</LoadingButton>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					></Box>
				</Stack>
			</Container>
		</Box>
	)
}

export default ResetPasswordForm
