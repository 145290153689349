import { Reservation } from 'types'
import { type Dayjs } from 'dayjs'

const SHIFT_DURATION_HOURS = 8

export const calculateReservationPrice = (
  start: Dayjs,
  end: Dayjs,
  price?: number,
  price_per_shift?: number
) => {
  if (end < start || price === undefined || price_per_shift === undefined) {
    return '---'
  }

  let reservationDurationMinutes = Math.floor(
    Math.abs((start as any) - (end as any)) / 1000 / 60
  )
  let reservationDurationHalfHours = Math.floor(reservationDurationMinutes / 30)

  let reservationPrice = (reservationDurationHalfHours * price) / 2

  if (reservationDurationMinutes % 30 !== 0) {
    reservationPrice = reservationPrice + price / 2
  }

  if (reservationDurationMinutes <= 60) {
    return price
  }

  return Math.min(reservationPrice, price_per_shift)
}

export function calculateDuration(start: Dayjs, end: Dayjs) {
  let hours = end.diff(start, 'hour')
  let minutes = end.diff(start, 'minute') % 60

  return { hours: hours, minutes: minutes }
}
