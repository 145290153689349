import { Routes, Route } from 'react-router'
import Login from 'pages/Login'
import Registration from 'pages/Registration'
import ReservationCalendar from 'pages/ReservationCalendar'
import Profile from 'pages/Profile'
import Logout from 'pages/Logout'
import ReservationsByDay from 'pages/ReservationsByDay'
import { UserSubscriptions } from 'pages/UserSubscriptions'
import { PasswordChangeForm } from 'pages/PasswordChangeForm'
import ResetPasswordForm from 'pages/ResetPassword'

export const AuthorizedRoutes = () => {
	return (
		<Routes>
			<Route path='profile/'>
				<Route path='change_password' element={<PasswordChangeForm />} />
				<Route path='self' element={<Profile />} />
			</Route>
			<Route path='reservation/'>
				<Route path='list' element={<ReservationsByDay />} />
				<Route path='calendar' element={<ReservationCalendar />} />
			</Route>
			<Route path='subscriptions/' element={<UserSubscriptions />} />
			<Route path='registration' element={<Registration />} />
			<Route path='logout' element={<Logout />} />
			<Route path='reset_password' element={<ResetPasswordForm/>}/>
			<Route path='*' element={<ReservationCalendar />} />
		</Routes>
	)
}

export const UnauthorizedRoutes = () => {
	return (
		<Routes>
			<Route path='login' element={<Login />} />
			<Route path='registration' element={<Registration />} />
			<Route path='reset_password' element={<ResetPasswordForm/>}/>
			<Route path='*' element={<Login />} />
		</Routes>
	)
}
