import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid'
import { Reservation } from 'types'
import { calculateDuration } from 'utils/reservationUtils'
import dayjs, { Dayjs } from 'dayjs'
import { useQuery } from 'react-query'
import { Button, CircularProgress, TextField } from '@mui/material'
import { getUserReservations } from 'api/reservationApi'
import { StaticDatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Container } from '@mui/system'
import {
	pickersDayClasses,
	PickersDay,
	PickersDayProps,
} from '@mui/x-date-pickers/PickersDay'
import styled from 'styled-components'
import { useNavigate } from 'react-router'

interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
	reservationExists: boolean
}

const CustomPickersDay = styled(PickersDay)<CustomPickerDayProps>(
	({ theme, reservationExists }) => ({
		[`&&.${pickersDayClasses.root}`]: {
			color: reservationExists ? '#008e09' : '',
			fontSize: '1.1em',
		},
		[`&&.${pickersDayClasses.selected}`]:{
			color: reservationExists ? '#97d389' : '',
		}
	})
) as React.ComponentType<CustomPickerDayProps>

export default function ReservationCalendar() {
	const handleReservationMenuOpen = (value: Dayjs | null) => {
		setSelectedDate(value)
		navigate('/reservation/list', {state: {
			year: value?.year(),
			month: value?.month(),
			date: value?.date(),
		}})
	}

	const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null)
	const [calendarMonth, setCalendarMonth] = useState<Dayjs | null>(dayjs())
	const [calendarYear, setCalendarYear] = useState<Dayjs | null>(dayjs())

	const navigate = useNavigate()

	const clientReservationsQuery = useQuery(
		['clientReservations', calendarMonth, calendarYear],
		() => {
			if (calendarMonth !== null && calendarYear !== null)
				return getUserReservations(
					calendarYear.year(),
					calendarMonth.month() + 1
				)
		}
	)

	useEffect(() => {
		if (clientReservationsQuery.data) {
			setDatesWithReservations(
				getDatesWithReservations(clientReservationsQuery.data.body)
			)
		} else {
			setDatesWithReservations([])
		}
	}, [clientReservationsQuery.data])

	const [datesWithReservations, setDatesWithReservations] = useState<
		Array<number>
	>([])

	const getDatesWithReservations = (reservations: Array<Reservation>) => {
		let datesArray: Array<number> = []

		if (!clientReservationsQuery.data) return []

		clientReservationsQuery.data.body.forEach((x) => {
			let start = dayjs(x.reservation_start).tz('Asia/Yekaterinburg')

			if (datesArray.indexOf(start.date()) === -1) datesArray.push(start.date())
		})

		return datesArray
	}

	const renderWeekPickerDay = (
		date: Dayjs,
		selectedDates: Array<Dayjs | null>,
		pickersDayProps: PickersDayProps<Dayjs>,
		reservationsDates: Array<number>
	) => {
		if (!date || !clientReservationsQuery.data) {
		return (
			<CustomPickersDay
				{...pickersDayProps}
				reservationExists={false}
			/>
		)
		}

		let reservationExists = false

		if (reservationsDates.indexOf(date.date()) !== -1) {
			reservationExists = true
		}

		return (
			<CustomPickersDay
				{...pickersDayProps}
				reservationExists={reservationExists}
			/>
		)
	}

	return (
		<Container
			sx={{
				justifyContent: 'center',
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
				<Box sx={{ maxWidth: '400px', marginTop: '30px' }}>
					<StaticDatePicker
						toolbarTitle='Выберите дату'
						openTo='day'
						onAccept={handleReservationMenuOpen}
						value={selectedDate}
						onChange={setSelectedDate}
						renderInput={(params) => <TextField {...params} />}
						renderDay={(day, selectedDays, pickerDayProps) =>
							renderWeekPickerDay(
								day,
								selectedDays,
								pickerDayProps,
								datesWithReservations
							)
						}
						onMonthChange={(month) => {
							setDatesWithReservations([])
							setCalendarMonth(month)
						}}
						onYearChange={(year) => {
							setDatesWithReservations([])
							setCalendarYear(year)
						}}
					/>
				</Box>
			</LocalizationProvider>
		</Container>
	)
}
