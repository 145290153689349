import {
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper,
} from '@mui/material'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { FreeTimeInterval } from 'types'
import stepIconClasses from '@mui/material'
import { Box } from '@mui/system'

const CustomConnector = styled(StepConnector)(({}) => ({
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#4caf50',
    borderTopWidth: 3,
    borderRadius: 1,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#ef5350',
    },
  },
}))

const CustomStep = styled(Step)(({}) => ({
  [`& .MuiStepLabel-root .Mui-active`]: {
    color: '#ef5350',
  },
  '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
    color: '#6f706f', // Just text label (ACTIVE)
		fontWeight: '400'
  },
}))

const CustomStepIcon = styled(Box)((props: { active: boolean }) => ({
  borderRadius: '50%',
  width: 24,
  height: 24,
  backgroundColor: props.active ? '#ef5350' : '#4caf50',
}))

export const FreeIntervalsStepper = (props: {
  freeIntervals: Array<FreeTimeInterval> | null
}) => {
  if (!props.freeIntervals) return null

  const populateTimeline = () => {
    let timeLineItems = []

    let dayjsFreeInteravls = props.freeIntervals!.map((interval) => {
      let start = dayjs.utc(interval.start, 'HH:mm:ss').tz('Asia/Yekaterinburg')
      let end = dayjs.utc(interval.end, 'HH:mm:ss').tz('Asia/Yekaterinburg')

      return [start, end]
    })

    let firstIntervalStart = dayjsFreeInteravls[0][0]

    if (firstIntervalStart.format('HH:mm') !== '10:00') {
      timeLineItems.push(
        <CustomStep key='-1'>
          <StepLabel
            StepIconComponent={CustomStepIcon}
            StepIconProps={{ active: true }}
          >
            {'10:00'}
          </StepLabel>
        </CustomStep>
      )
    }

    for (let i = 0; i < dayjsFreeInteravls.length; i++) {
      let freeInterval = dayjsFreeInteravls[i]
      let start = freeInterval[0]
      let end = freeInterval[1]

      timeLineItems.push(
        <CustomStep active key={start.format('HH:mm')}>
          <StepLabel
            StepIconComponent={CustomStepIcon}
            StepIconProps={{ active: false }}
          >
            {start.format('HH:mm')}
          </StepLabel>
        </CustomStep>
      )
      timeLineItems.push(
        <CustomStep key={end.format('HH:mm')}>
          <StepLabel
            StepIconComponent={CustomStepIcon}
            StepIconProps={{ active: false }}
          >
            {end.format('HH:mm')}
          </StepLabel>
        </CustomStep>
      )
    }

    let lastIntervalEnd = dayjsFreeInteravls[dayjsFreeInteravls.length - 1][1]

    if (lastIntervalEnd.format('HH:mm') !== '22:00') {
      timeLineItems.push(
        <CustomStep active key='-2'>
          <StepLabel
            StepIconComponent={CustomStepIcon}
            StepIconProps={{ active: true  }}
          >
            {'22:00'}
          </StepLabel>
        </CustomStep>
      )
    }

    return timeLineItems
  }

  return (
    <Stepper alternativeLabel connector={<CustomConnector />}>
      {populateTimeline()}
    </Stepper>
  )
}

export default FreeIntervalsStepper
