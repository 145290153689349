import { Reservation } from 'types'

export default function reservationReducer(
  state: Reservation,
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case 'setCommentary': {
      return {
        ...state,
        commentary: action.payload.commentary,
      }
    }
    case 'setCalendarColor': {
      return {
        ...state,
        calendar_color: action.payload.calendarColor,
      }
    }
    case 'setWorkplace': {
      return {
        ...state,
        workplace: action.payload.workplaceId,
      }
    }
    case 'setPaymentType': {
      return {
        ...state,
        payment: {
          ...state.payment,
          payment_type: action.payload.paymentType,
        },
        user_subscription: null,
      }
    }
    case 'setPaymentSpecialCost': {
      return {
        ...state,
        payment: {
          ...state.payment,
          special_cost: action.payload.specialCost,
        },
      }
    }
    case 'setUser': {
      return {
        ...state,
        user: action.payload.user,
      }
    }
    case 'setUserSubscription': {
      return {
        ...state,
        user_subscription: action.payload.userSubscription,
        payment: {
          ...state.payment,
          payment_type: null,
        },
      }
    }
    case 'setReservationStatus': {
      // if (action.payload.reservationStatus !== 2)
      //   return {
      //     ...state,
      //     reservation_status: action.payload.reservationStatus,
      //     user_subscription: null,
      //     payment: {
      //       ...state.payment,
      //       payment_type: null,
      //     },
      //   }
      // else
        return {
          ...state,
          reservation_status: action.payload.reservationStatus,
        }
    }
    case 'reset': {
      return {
        ...action.payload,
      }
    }
    case 'setReservationStart': {
      return {
        ...state,
        reservation_start: action.payload.reservationStart,
      }
    }
    case 'setReservationEnd': {
      return {
        ...state,
        reservation_end: action.payload.reservationEnd,
      }
    }
  }
}

