import {
	ApiResponse,
	Reservation,
	ReservationStatus,
	WorkplaceFreeTimeIntervals,
} from 'types'
import { BASE_URL } from './config'
import { sendAuthorizedRequest } from './jwtApi'

export async function getFreeTimeIntervals(
	date: string,
	reservation_id?: number,
): Promise<ApiResponse<Array<WorkplaceFreeTimeIntervals>>> {
	let reservation_endpoint_url =
		BASE_URL + `booking/reservations/free_time/?date=${date}`
	
	if (reservation_id)
		reservation_endpoint_url += `&reservation_id=${reservation_id}`

	// reservation_endpoint_url += "/"


	let result = await sendAuthorizedRequest(reservation_endpoint_url)

	return {
		status_code: result.status,
		ok: result.ok,
		body: JSON.parse(await result.json()),
	}
}

export async function postReservation(
	reservationObject: object,
	method: string = 'POST'
): Promise<ApiResponse<object>> {
	let reservations_endpoint_url = BASE_URL + 'booking/reservations/'

	let fetch_options = {
		method: method,
		headers: {
			'Content-Type': 'application/json;charset=utf-8',
		},
		body: JSON.stringify(reservationObject),
	}

	let result = await sendAuthorizedRequest(
		reservations_endpoint_url,
		fetch_options
	)


	let resultBody = null

	try {
		resultBody = await result.json()
	} catch (error) {
		resultBody = null
	}

	return {
		status_code: result.status,
		ok: result.ok,
		body: resultBody
	}
}

export async function updateReservation(
	reservationObject: Reservation,
	method: string = 'PUT'
): Promise<ApiResponse<object>> {
	let reservations_endpoint_url =
		BASE_URL + `booking/reservations/${reservationObject.id}/`

	let fetch_options = {
		method: method,
		headers: {
			'Content-Type': 'application/json;charset=utf-8',
		},
		body: JSON.stringify(reservationObject),
	}

	let result = await sendAuthorizedRequest(
		reservations_endpoint_url,
		fetch_options
	)

	let resultBody = null

	try {
		resultBody = await result.json()
	} catch (error) {
		resultBody = null
	}

	return {
		status_code: result.status,
		ok: result.ok,
		body: resultBody
	}
}

export async function deleteReservation(
	reservationId: number
): Promise<ApiResponse<object>> {
	let reservation_endpoint_url =
		BASE_URL + `booking/reservations/${reservationId}/`

	let fetch_options = {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json;charset=utf-8',
		},
	}

	let result = await sendAuthorizedRequest(
		reservation_endpoint_url,
		fetch_options
	)

	let resultBody = null

	try {
		resultBody = await result.json()
	} catch (error) {
		resultBody = null
	}

	return {
		status_code: result.status,
		ok: result.ok,
		body: resultBody,
	}
}

export async function getUserReservations(
	year: number,
	month: number,
	day?: number
): Promise<ApiResponse<Array<Reservation>>> {
	let reservations_endpoint_url =
		BASE_URL + `booking/reservations/self/?year=${year}&month=${month}`

	if (day) {
		reservations_endpoint_url += `&day=${day}`
	}

	let fetch_options = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json;charset=utf-8',
		},
	}

	let result = await sendAuthorizedRequest(
		reservations_endpoint_url,
		fetch_options
	)

	return {
		status_code: result.status,
		ok: result.ok,
		body: await result.json(),
	}
}

export async function getReservationStatuses(): Promise<
	ApiResponse<Array<ReservationStatus>>
> {
	let reservation_statuses_endpoint_url =
		BASE_URL + `booking/reservation_statuses/`

	let result = await sendAuthorizedRequest(reservation_statuses_endpoint_url)

	return {
		status_code: result.status,
		ok: result.ok,
		body: await result.json(),
	}
}
