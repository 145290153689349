import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid'
import { Reservation } from 'types'
import { calculateDuration } from 'utils/reservationUtils'
import dayjs, { Dayjs, locale } from 'dayjs'
import { useQuery } from 'react-query'
import {
	Button,
	CircularProgress,
	Divider,
	Fab,
	TextField,
	Typography,
} from '@mui/material'
import { getUserReservations } from 'api/reservationApi'
import ReservationList from 'components/ReservationList'
import ReservationDialog from 'components/ReservationDialog'
import { useLocation } from 'react-router'
import AddIcon from '@mui/icons-material/Add'

export default function ReservationsByDay() {
	const location = useLocation() as any

	const clientReservationsQuery = useQuery('reservations', () =>
		getUserReservations(
			parseInt(location.state.year),
			parseInt(location.state.month) + 1,
			parseInt(location.state.date)
		)
	)
	const [selectedReservation, setSelectedReservation] =
		useState<Reservation | null>(null)
	const [dialogOpen, setDialogOpen] = useState<boolean>(false)

	const onReservationSelection = (reservation: Reservation) => {
		setSelectedReservation(reservation)
		setDialogOpen(true)
	}

	return (
		<>
			<Fab
				aria-label='add'
				onClick={() => setDialogOpen(true)}
				variant={'extended'}
				sx={{
					position: 'fixed',
					left: 'auto',
					top: 'auto',
					right: '20px',
					bottom: '20px',
				}}
			>
				<AddIcon />
				<Typography>Сделать запись</Typography>
			</Fab>
			<Typography variant='h5' mt='10px' ml='10px'>
				Ваши записи на{' '}
				{dayjs()
					.date(location.state.date)
					.month(location.state.month)
					.year(location.state.year)
					.format('dddd, MMMM DD')}
			</Typography>
			<Divider></Divider>
			<Box sx={{ paddingX: '20px' }}>
				{clientReservationsQuery.isLoading && <CircularProgress />}
				{!clientReservationsQuery.isLoading && (
					<ReservationList
						sx={{maxWidth: '400px', margin: '0 auto'}}
						onSelect={onReservationSelection}
						reservations={clientReservationsQuery.data!.body}
					/>
				)}
			</Box>
			<ReservationDialog
				startDate={dayjs().date(location.state.date).month(location.state.month).year(location.state.year)}
				reservationObject={selectedReservation}
				dialogOpen={dialogOpen}
				handleDialogClose={() => {
					setSelectedReservation(null)
					setDialogOpen(false)
				}}
			/>
		</>
	)
}
