import {SxProps} from '@mui/material/styles'
import { Box } from '@mui/system'
import { Reservation } from 'types'
import ReservationListItem from './ReservationListItem'

export const ReservationList = (props: {
	sx?: SxProps
  reservations: Array<Reservation>
	onSelect?: any
}) => {
  return (
    <Box
			 sx={[
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
			>
      {props.reservations.map((reservation) => {
        return (
          <ReservationListItem
						key={reservation.id}
            margintop='10px'
            reservation={reservation}
            onClick={() => props.onSelect(reservation)}
          />
        )
      })}
    </Box>
  )
}

export default ReservationList
