import { AuthorizedRoutes, UnauthorizedRoutes } from 'components/Routes'
import SnackbarCloseButton from 'components/SnackbarCloseButton'
import { SnackbarProvider } from 'notistack'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import JwtRefresher from 'components/JwtRefresher'
import ProfileBase from 'pages/ProfileBase'
import dayjs from 'dayjs'
import { ruRU } from '@mui/material/locale'
import { AuthorizationCheck } from 'components/AuthorizationCheck'
import { getSavedJwtTokens } from 'api/jwtApi'
import React, { useState } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import 'dayjs/locale/ru'

dayjs.locale('ru')

const theme = createTheme(
	{
		typography: {
			fontFamily: 'roboto',
			fontWeightLight: 300,
			fontWeightRegular: 400,
			fontWeightMedium: 500,
			fontWeightBold: 600,
		},
	},
	ruRU
)

const queryClient = new QueryClient()

function App() {
	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
		() => getSavedJwtTokens().access !== null
	)

	return (
		<React.Fragment>
			<ThemeProvider theme={theme}>
				<SnackbarProvider
					maxSnack={3}
					action={(key) => <SnackbarCloseButton id={key as number} />}
				>
					<QueryClientProvider client={queryClient}>
						<ProfileBase>
							{isLoggedIn && (
								<React.Fragment>
									<JwtRefresher />
									<AuthorizedRoutes />
								</React.Fragment>
							)}
							{!isLoggedIn && <UnauthorizedRoutes />}
						</ProfileBase>
					</QueryClientProvider>
				</SnackbarProvider>
			</ThemeProvider>
		</React.Fragment>
	)
}

export default App
