import { updateJwtToken } from 'api/jwtApi'
import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router'

const MINUTE_MS = 60000

const JwtRefresher = () => {
	const navigate = useNavigate()

	const jwtQuery = useQuery('jwt', updateJwtToken, {
		onSuccess: (data) => {
			if (!data.ok) {
				navigate('/login', { replace: true })
			}
		},
		onError: (error) => {
			navigate('/login', { replace: true })
		},
		refetchInterval: MINUTE_MS,
		refetchIntervalInBackground: true,
		refetchOnWindowFocus: false,
	})

	return <React.Fragment></React.Fragment>
}

export default JwtRefresher
