import { Stack, TextField, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import LoadingButton from '@mui/lab/LoadingButton'
import { useState } from 'react'
import { useSnackbar } from 'notistack'
import { login } from 'api/jwtApi'
import { useMutation, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'

export const Login = () => {
	const queryClient = useQueryClient()

	const [username, setUsername] = useState<string>('')
	const [password, setPassword] = useState<string>('')

	const { enqueueSnackbar } = useSnackbar()

	const handleUsernameChange = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		setUsername(event.target.value)
	}

	const handlePasswordChange = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		setPassword(event.target.value)
	}

	const validateLoginData = () => {
		if (username === '') {
			throw 'Введите логин'
		}

		if (password === '') {
			throw 'Введите пароль'
		}
	}

	const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()
		e.stopPropagation()

		validateLoginData()

		let result = await login(username.toLowerCase(), password)

		if (!result.ok) {
			let error = result.body as any

			for (let key in error) {
				if (!Array.isArray(error[key])) continue

				error[key] = error[key].map((x: string) => {
					let errorString = `${key}: ${x}`
						.replace('password', 'пароль')
						.replace('username', 'логин')
						.replace('user', 'пользователь')

					return errorString
				})
			}

			if (Object.values(error).length > 0) throw Object.values(error)[0]
			else throw 'Неверный логин или пароль'
		}
	}

	const authenticationMutation = useMutation(handleSubmit, {
		onSuccess: () => {
			enqueueSnackbar('Успешный вход', { variant: 'success' })
			queryClient.invalidateQueries('jwt')
			window.location.reload()
		},
		onError: (error: string) => {
			enqueueSnackbar(error, { variant: 'error' })
		},
	})

	return (
		<Box>
			<Container
				maxWidth='sm'
				sx={{
					height: '100%',
					paddingTop: '10%',
				}}
			>
				<Stack
					spacing={1}
					sx={{
						height: 'fit-content',
						width: '100%',
					}}
					component='form'
					onSubmitCapture={authenticationMutation.mutate}
				>
					<Typography variant='h5'>Авторизация</Typography>
					<TextField
						label='Логин или эл. почта'
						fullWidth
						required
						value={username}
						onChange={handleUsernameChange}
					/>
					<Box>
						<TextField
							label='Пароль'
							fullWidth
							required
							value={password}
							onChange={handlePasswordChange}
							type='password'
						/>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<Typography variant='body2'>Забыли пароль?</Typography>
							<Link to='/reset_password'>
								<Typography variant='body2'>Сбросить пароль</Typography>
							</Link>
						</Box>
					</Box>
					<Box>
						<LoadingButton
							variant='outlined'
							fullWidth
							type='submit'
							className='button'
							loading={authenticationMutation.isLoading}
						>
							Войти
						</LoadingButton>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<Typography variant='body2'>Нет аккаунта?</Typography>
							<Link to='/registration'>
								<Typography variant='body2'>Зарегистрироваться</Typography>
							</Link>
						</Box>
					</Box>
				</Stack>
			</Container>
		</Box>
	)
}

export default Login
