import { Stack, TextField, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import LoadingButton from '@mui/lab/LoadingButton'
import { useState } from 'react'
import { UserRegistrationData } from 'types'
import { changePassword, registerUser } from 'api/userApi'
import { useMutation } from 'react-query'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'

export const PasswordChangeForm = () => {
	const [userPasswordChangeData, setUserPasswordChangeData] = useState({
		password: '',
		new_password: '',
	})

	const [repeatPassword, setRepeatPassword] = useState<string>('')

	const { enqueueSnackbar } = useSnackbar()

	const navigate = useNavigate()

	const handleRepeatPasswordChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRepeatPassword(event.target.value)
	}

	const handleUserPasswordChangeData = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		setUserPasswordChangeData((prev) => ({
			...prev,
			[event.target.name]: event.target.value,
		}))
	}

	const validatePasswordChangeData = () => {
		if (repeatPassword !== userPasswordChangeData.new_password)
			throw 'Пароли не совпадают'
	}

	const handleUserPasswordChange = async (
		e: React.FormEvent<HTMLFormElement>
	) => {
		e.preventDefault()
		e.stopPropagation()

		validatePasswordChangeData()

		let result = await changePassword(userPasswordChangeData)

		if (!result.ok) {
			let error = result.body as any

			for (let key in error) {
				if (!Array.isArray(error[key])) continue

				error[key] = error[key].map((x: string) => {
					let errorString = `${key}: ${x}`
						.replaceAll('user', 'пользователь')
						.replaceAll('new_password', 'Новый пароль')
						.replaceAll('password', 'Пароль')

					return errorString
				})
			}

			if (Object.values(error).length > 0) {
				let errorArray = Object.values(error)[0] as any
				throw errorArray[0]
			}
		}
	}

	const userRegistrationMutation = useMutation(handleUserPasswordChange, {
		onSuccess: () => {
			enqueueSnackbar('Пароль успешно изменен', { variant: 'success' })
			setTimeout(() => navigate('/profile/self', {replace: true}), 1000)
		},
		onError: (error: string) => {
			enqueueSnackbar(error, { variant: 'error' })
		},
	})

	return (
		<Box>
			<Container
				maxWidth='sm'
				sx={{
					height: '100%',
					paddingTop: '10%',
				}}
			>
				<Stack
					spacing={1}
					sx={{
						height: 'fit-content',
						width: '100%',
					}}
					component='form'
					onSubmit={userRegistrationMutation.mutate}
				>
					<Typography variant='h5'>Изменение пароля</Typography>
					<TextField
						required
						label='Старый пароль'
						name='password'
						type='password'
						onChange={handleUserPasswordChangeData}
						fullWidth
						value={userPasswordChangeData.password}
					/>
					<TextField
						required
						label='Новый пароль'
						type='password'
						name='new_password'
						onChange={handleUserPasswordChangeData}
						fullWidth
						value={userPasswordChangeData.new_password}
					/>
					<TextField
						required
						label='Новый пароль (повторно)'
						type='password'
						fullWidth
						onChange={handleRepeatPasswordChange}
						value={repeatPassword}
					/>
					<LoadingButton
						variant='outlined'
						fullWidth
						type='submit'
						loading={userRegistrationMutation.isLoading}
					>
						Поменять пароль
					</LoadingButton>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					></Box>
				</Stack>
			</Container>
		</Box>
	)
}

export default PasswordChangeForm
