import { Button, Fab } from '@mui/material'
import { Box } from '@mui/system'
import DrawerMenu from 'components/DrawerMenu'
import { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import AppBar from 'components/AppBar'
import { getSavedJwtTokens } from 'api/jwtApi'

export const ProfileBase = (props: { children: React.ReactNode }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
    () => getSavedJwtTokens().access !== null
  )

  const onDrawerClose = () => {
    setIsDrawerOpen(false)
  }
  const onDrawerOpen = () => {
    setIsDrawerOpen(true)
  }

  return (
    <Box>
      <AppBar showMenuIcon={isLoggedIn} onMenuButtonClick={onDrawerOpen} />
      {isLoggedIn && (
        <DrawerMenu
          width='200px'
          isOpen={isDrawerOpen}
          onOpen={onDrawerOpen}
          onClose={onDrawerClose}
        />
      )}
      <Box sx={{marginTop: '80px'}}>{props.children}</Box>
    </Box>
  )
}

export default ProfileBase
