import { BASE_URL } from 'api/config'

import { ApiResponse } from 'types'

export async function login(
	username: string,
	password: string
): Promise<ApiResponse<any>> {
	let login_endpoint_url = BASE_URL + 'auth/token/'

	let payload = {
		username: username,
		password: password,
	}

	let result = await fetch(login_endpoint_url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json;charset=utf-8',
		},
		body: JSON.stringify(payload),
	})

	if (result.ok) {
		let jwtTokens = await result.json()
		saveJwtTokens(jwtTokens.access, jwtTokens.refresh)
	}

	return {
		status_code: result.status,
		ok: result.ok,
		body: {}
	}
}

export function clearJwtTokens(): void {
	localStorage.removeItem('access_token')
	localStorage.removeItem('refresh_token')
}

function saveJwtTokens(access_token: string, refresh_token: string): void {
	if (access_token) localStorage.setItem('access_token', access_token)
	if (refresh_token) localStorage.setItem('refresh_token', refresh_token)
}

export function getSavedJwtTokens(): {
	refresh: string | null
	access: string | null
} {
	let refresh_token = localStorage.getItem('refresh_token')
	let access_token = localStorage.getItem('access_token')

	return {
		refresh: refresh_token,
		access: access_token,
	}
}

export async function updateJwtToken(): Promise<ApiResponse<null>> {
	let savedTokens = getSavedJwtTokens()

	if (!savedTokens.access || !savedTokens.refresh)
		throw "can't update tokens if tokens are not set"

	let refresh_token_url = BASE_URL + 'auth/token/refresh/'

	let result = await fetch(refresh_token_url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			refresh: savedTokens.refresh,
		}),
	})

	if (result.ok) {

		let jwt_tokens = await result.json()
		saveJwtTokens(jwt_tokens.access, jwt_tokens.refresh)
	}

	return {
		status_code: result.status,
		ok: result.ok,
		body: null
	}
}

export async function sendAuthorizedRequest(
	url: string,
	params: { headers?: Record<string, string> } = { headers: undefined },
	relocateOnFail: boolean = true
) {
	let jwt_tokens = getSavedJwtTokens()

	if (!params.headers) {
		params.headers = {}
	}

	params.headers.Authorization = 'Bearer ' + jwt_tokens.access

	let result = await fetch(url, params)

	if (result.status === 401) {
		clearJwtTokens()
		if (relocateOnFail)
			window.location.href = '/login'
	}

	return result
}

