import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useSnackbar } from 'notistack'

function SnackbarCloseButton(props: { id: number }) {
  const { closeSnackbar } = useSnackbar()

  return (
    <IconButton onClick={() => closeSnackbar(props.id)} color='inherit'>
      <CloseIcon />
    </IconButton>
  )
}

export default SnackbarCloseButton
