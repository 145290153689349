import { Checkbox, Divider, FormControlLabel, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { getUserSubscriptions } from 'api/subscriptionApi'
import { getSelfData } from 'api/userApi'
import UserSubscriptionSelectionList from 'components/UserSubscriptionSelectionList'
import { useState } from 'react'
import { useQuery } from 'react-query'

export const UserSubscriptions = () => {
	const userSubscriptionsQuery = useQuery(
		'userSubscriptions',
		getUserSubscriptions
	)

	const [showNotActiveSubscriptions, setShowNotActiveSubscriptions] = useState(false)

	const handleSetShowNotActiveSubscriptions = (event: React.ChangeEvent<HTMLInputElement>) => {
		setShowNotActiveSubscriptions(event.target.checked);
	};

	return (
		<Container>
			<Typography variant='h5' mt='10px' ml='10px'>
				Ваши абонементы
			</Typography>
			<Divider></Divider>
			<FormControlLabel
				control={<Checkbox defaultChecked checked={showNotActiveSubscriptions} onChange={handleSetShowNotActiveSubscriptions} />}
				label="Показывать неактивные абонементы" />
			{userSubscriptionsQuery.data?.body && (
				<UserSubscriptionSelectionList
					clientSubscriptions={userSubscriptionsQuery.data?.body}
					showNotActive={showNotActiveSubscriptions}
				/>
			)}
		</Container>
	)
}
