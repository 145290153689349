import { useState, useEffect, useReducer } from 'react'
import { type Dayjs } from 'dayjs'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
  Dialog,
  DialogContent,
  TextField,
  Divider,
  DialogTitle,
  Typography,
  IconButton,
  Stack,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import LoadingButton from '@mui/lab/LoadingButton'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import {
  deleteReservation,
  getFreeTimeIntervals,
  getReservationStatuses,
  postReservation,
  updateReservation,
} from 'api/reservationApi'
import { getUserSubscriptionsByUser } from 'api/subscriptionApi'
import WorkplaceSelector from './WorkplaceSelector'
import StyledTimePicker from './StyledTimePicker'
import { calculateReservationPrice } from 'utils/reservationUtils'
import { useSnackbar } from 'notistack'
import reservationReducer from 'reducers/reservationReducer'
import { Reservation } from 'types'
import { calculateDuration } from 'utils/reservationUtils'
import { useMutation, useQueryClient } from 'react-query'
import { getWorkplaceConfig, getWorkplaces } from 'api/workplaceApi'
import { useQuery } from 'react-query'
import { getPaymentTypes } from 'api/paymentApi'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { Box } from '@mui/system'
import FreeIntervalsStepper from './FreeIntervalsStepper'
import WorkplaceConfigEntrySelector from './WorkplaceConfigEntrySelector'

dayjs.extend(utc)
dayjs.extend(timezone)

const DialogContentBlock = styled.div`
  min-width: 250px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`

function ReservationDialog(props: {
  startDate?: Dayjs
  reservationObject: Reservation | null
  dialogOpen: boolean
  handleDialogClose: any
}) {
  const queryClient = useQueryClient()
  const [reservation, dispatch] = useReducer(reservationReducer, {
    reservation_start: dayjs().second(0).millisecond(0),
    reservation_end: '',
    commentary: '',
    workplace: '',
    reservation_status: '',
    user_subscription: null,
    user: null,
    calendar_color: '',
  })
  const [reservationDate, setReservationDate] = useState<Dayjs | null>(
    props.startDate || dayjs()
  )
  const { enqueueSnackbar } = useSnackbar()

  const reservationStatusesQuery = useQuery(
    'reservationStatuses',
    getReservationStatuses,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  )

  const workplaceConfigQuery = useQuery(
    ['workplaceConfig', reservationDate],
    () => {
      if (reservationDate !== null && reservationDate.isValid())
        return getWorkplaceConfig(reservationDate)
    }
  )

  const getFreeIntervalsByWorkplace = () => {
    if (
      reservationFreeIntervalsQuery.data === undefined ||
      reservationFreeIntervalsQuery.data === null
    )
      return null

    let filterResult = reservationFreeIntervalsQuery.data.body
      .filter((x) => x.workplace_id === reservation.workplace)

    if (filterResult.length > 0) return filterResult[0].intervals
		return []
  }

  const fetchFreeIntervals = () => {
    if (!reservationDate || !reservationDate.isValid()) return null

    let result = null

    if (props.reservationObject?.id) {
      result = getFreeTimeIntervals(
        reservationDate.format('YYYY-MM-DD'),
        props.reservationObject.id
      )
    } else {
      result = getFreeTimeIntervals(reservationDate.format('YYYY-MM-DD'))
    }

    return result
  }

  const reservationFreeIntervalsQuery = useQuery(
    ['reservationFreeIntervals', reservationDate],
    fetchFreeIntervals,
    {
      refetchInterval: 1000 * 30,
    }
  )

  const workplaceQuery = useQuery('workplaces', getWorkplaces, {
    staleTime: Infinity,
    cacheTime: Infinity,
  })

  const handleSelectedWorkplaceChange = (workplaceId: number) => {
		console.log(reservation.workplace)
    dispatch({ type: 'setWorkplace', payload: { workplaceId: workplaceId } })
  }

  const validateReservation = () => {
    if (
      reservation.reservation_start === null ||
      reservation.reservation_end === null
    ) {
      throw 'Начало или конец записи не выставлены'
    }

    if (
      reservation.reservation_start.minute() % 30 !== 0 ||
      reservation.reservation_end.minute() % 30 !== 0
    ) {
      throw 'Шаг бронирования - 30 минут'
    }

    if (
      reservation.reservation_end.diff(reservation.reservation_start, 'hour') <
      1
    ) {
      throw 'Минимальная длительность записи - 1 час'
    }
  }

  const handleSave = async () => {
    validateReservation()

    let reservationObject = {
      ...reservation,
      id: reservation.id,
      reservation_end: reservationDate!
        .set('hour', reservation.reservation_end.hour())
        .set('minute', reservation.reservation_end.minute())
        .set('second', 0)
        .set('millisecond', 0)
        .tz('Asia/Yekaterinburg', true)
        .toISOString(),
      reservation_start: reservationDate!
        .set('hour', reservation.reservation_start.hour())
        .set('minute', reservation.reservation_start.minute())
        .set('second', 0)
        .set('millisecond', 0)
        .tz('Asia/Yekaterinburg', true)
        .toISOString(),
      commentary: reservation.commentary,
    }

    let result = null

    if (props.reservationObject) {
      reservationObject.id = props.reservationObject.id
      result = await updateReservation(reservationObject, 'PUT')
    } else {
      result = await postReservation(reservationObject, 'POST')
    }

    if (!result.ok) {
      let error = result.body as any

      for (let key in error) {
        if (!Array.isArray(error[key])) continue

        error[key] = error[key].map((x: string) => {
          let errorString = `${key}: ${x}`
            .replace('reservation_start', 'Начало брони')
            .replace('reservation_end', 'Конец брони')
            .replace('workplace', 'Рабочее место')

          return errorString
        })
      }

      if (Object.values(error).length > 0) throw Object.values(error)[0]
      else throw error.detail
    }

    //quit dialog automatically if creating
    if (!props.reservationObject?.id) props.handleDialogClose()
  }

  const handleDelete = async () => {
    if (props.reservationObject == null) throw 'error'

    let result = await deleteReservation(props.reservationObject.id)

    if (!result.ok) {
      let error = result.body as any

      for (let key in error) {
        if (!Array.isArray(error[key])) continue

        error[key] = error[key].map((x: string) => {
          let errorString = `${key}: ${x}`
            .replace('reservation_start', 'Начало брони')
            .replace('reservation_end', 'Конец брони')
            .replace('workplace', 'Рабочее место')

          return errorString
        })
      }

      if (Object.values(error).length > 0) throw Object.values(error)[0]
      else throw error.detail
    } else {
      props.handleDialogClose()
    }
  }

  const reservationSaveMutation = useMutation(handleSave, {
    onSuccess: () => {
      enqueueSnackbar('Сохранено', { variant: 'success' })
      queryClient.invalidateQueries('reservations')
      queryClient.invalidateQueries('userSubscriptions')
    },
    onError(error: string, variables, context) {
      enqueueSnackbar(error, { variant: 'error' })
    },
  })

  const reservationDeleteMutation = useMutation(handleDelete, {
    onSuccess: () => {
      enqueueSnackbar('Удалено', { variant: 'success' })
      queryClient.invalidateQueries('reservations')
      queryClient.invalidateQueries('userSubscriptions')
    },
    onError(error: string, variables, context) {
      enqueueSnackbar(error, { variant: 'error' })
    },
  })

  useEffect(() => {
    if (props.reservationObject) {
      dispatch({
        type: 'reset',
        payload: {
          workplace: props.reservationObject.workplace.id,
          reservation_start: dayjs(
            props.reservationObject.reservation_start
          ).tz('Asia/Yekaterinburg'),
          reservation_end: dayjs(props.reservationObject.reservation_end).tz(
            'Asia/Yekaterinburg'
          ),
          user_subscription: props.reservationObject.user_subscription,
          payment: props.reservationObject.payment,
          reservation_status: props.reservationObject.reservation_status,
        },
      })
      setReservationDate(dayjs(props.reservationObject.reservation_start))
    } else {
      dispatch({
        type: 'reset',
        payload: {
          reservation_start: null,
          reservation_end: null,
          workplace:
            workplaceConfigQuery.data?.body.workplaces![0].workplace || 0,
          reservation_status: 1,
          user_subscription: null,
          payment: {
            payment_type: null,
            cost: null,
          },
        },
      })

      setReservationDate(props.startDate || dayjs())
    }
  }, [
    props.reservationObject,
    props.dialogOpen,
    workplaceQuery.data?.body,
    reservationStatusesQuery.data?.body,
  ])

  if (!reservationStatusesQuery.data || !workplaceQuery.data) return null

  return (
    <>
      <Dialog
        fullScreen
        open={props.dialogOpen}
        onClose={props.handleDialogClose}
      >
        <DialogTitle minWidth='250px' sx={{ paddingY: '10px' }}>
          <Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                rowGap: '10px',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                {props.reservationObject?.id && (
                  <LoadingButton
                    loading={
                      reservationDeleteMutation.isLoading ||
                      reservationSaveMutation.isLoading
                    }
                    onClick={() => reservationDeleteMutation.mutate()}
                    variant='contained'
                    color='error'
                  >
                    Удалить
                  </LoadingButton>
                )}
                <LoadingButton
                  loading={reservationSaveMutation.isLoading}
                  onClick={() => reservationSaveMutation.mutate()}
                  variant='contained'
                >
                  Сохранить
                </LoadingButton>
              </Box>
              <IconButton onClick={props.handleDialogClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent
          sx={{ maxWidth: '600px', width: '90%', alignSelf: 'center' }}
        >
          <Stack spacing={1}>
            <Box sx={{ display: 'flex', columnGap: '15px' }}>
              <Typography
                sx={{ display: 'flex', alignItems: 'center', columnGap: '5px' }}
              >
                <Box
                  sx={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: '#4caf50',
                  }}
                />
                - место свободно
              </Typography>
              <Typography
                sx={{ display: 'flex', alignItems: 'center', columnGap: '5px' }}
              >
                <Box
                  sx={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: '#ef5350',
                  }}
                />
                - место занято
              </Typography>
            </Box>
            {reservationFreeIntervalsQuery.data && (
              <Box sx={{ overflowY: 'hidden', overflowX: 'hidden' }}>
                <FreeIntervalsStepper
                  freeIntervals={getFreeIntervalsByWorkplace()}
                ></FreeIntervalsStepper>
              </Box>
            )}
            <Divider></Divider>

            <Box>
              <DialogContentBlock>
                {workplaceConfigQuery.data && (
                  <WorkplaceConfigEntrySelector
                    workplaceConfigEntries={
                      workplaceConfigQuery.data?.body.workplaces
                    }
                    selectedWorkplace={reservation.workplace}
                    handleSelectedWorkplaceChange={
                      handleSelectedWorkplaceChange
                    }
                    fullWidth
                  />
                )}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={1}>
                    <DesktopDatePicker
                      inputFormat='DD/MM/YYYY'
                      label='Дата аренды'
                      value={reservationDate}
                      onChange={setReservationDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    <StyledTimePicker
                      label='Начало'
                      value={reservation.reservation_start}
                      onChange={(value: Dayjs | null) => {
                        if (value?.isValid())
                          value = value.set('second', 0).set('millisecond', 0)

                        dispatch({
                          type: 'setReservationStart',
                          payload: {
                            reservationStart: value,
                          },
                        })
                      }}
                      minValue={dayjs()
                        .hour(10)
                        .minute(0)
                        .second(0)
                        .millisecond(0)}
                    />
                    <StyledTimePicker
                      label='Конец'
                      value={reservation.reservation_end}
                      onChange={(value: Dayjs | null) => {
                        if (value?.isValid())
                          value = value.set('second', 0).set('millisecond', 0)

                        dispatch({
                          type: 'setReservationEnd',
                          payload: {
                            reservationEnd: value,
                          },
                        })
                      }}
                      minValue={reservation.reservation_start?.add(1, 'hours')}
                    />
                  </Stack>
                </LocalizationProvider>
                <Box>
                  {reservation.reservation_start &&
                    reservation.reservation_end && (
                      <Box
                        sx={{
                          display: 'flex',
                          columnGap: '20px',
                        }}
                      >
                        <Box>
                          <Typography ml={'5px'}>
                            {reservation.reservation_end.diff(
                              reservation.reservation_start
                            ) >= 0 &&
                              `Длительность: ${
                                calculateDuration(
                                  reservation.reservation_start,
                                  reservation.reservation_end
                                ).hours
                              } ч. ${
                                calculateDuration(
                                  reservation.reservation_start,
                                  reservation.reservation_end
                                ).minutes
                              } м.`}
                          </Typography>
                          <Typography ml={'5px'}>
                            {`Цена: ${calculateReservationPrice(
                              reservation.reservation_start,
                              reservation.reservation_end,
                              workplaceConfigQuery.data?.body.workplaces.find(
                                (x) => {
                                  return x.workplace === reservation.workplace
                                }
                              )?.price,
                              workplaceConfigQuery.data?.body.workplaces.find(
                                (x) => {
                                  return x.workplace === reservation.workplace
                                }
                              )?.price_per_shift
                            )} ₽`}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                </Box>
                <TextField
                  label='Комментарий'
                  multiline
                  value={reservation.commentary}
                  name='commentary'
                  onChange={(event) =>
                    dispatch({
                      type: 'setCommentary',
                      payload: { commentary: event.target.value },
                    })
                  }
                />
              </DialogContentBlock>
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ReservationDialog
