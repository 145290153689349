import {
	AppBar,
	Box,
	Button,
	IconButton,
	Toolbar,
	Typography,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import ArrowLeft from '@mui/icons-material/ArrowBack'
import { useQuery } from 'react-query'
import { getSelfData } from 'api/userApi'
import { useNavigate } from 'react-router'

function StyledAppBar(props: {
	onMenuButtonClick?: any
	showMenuIcon?: boolean
}) {
	const userQuery = useQuery('user', getSelfData, {
		refetchOnWindowFocus: false,
	})
	const navigate = useNavigate()

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position='fixed' sx={{ backgroundColor: '#181a1b' }}>
				<Toolbar>
					{props.showMenuIcon && (
						<Box>
							<IconButton
								size='medium'
								edge='start'
								color='inherit'
								aria-label='menu'
								sx={{ mr: 2 }}
								onClick={props.onMenuButtonClick}
							>
								<MenuIcon />
							</IconButton>
							<IconButton
								size='medium'
								edge='start'
								color='inherit'
								aria-label='menu'
								sx={{ mr: 2 }}
								onClick={() => {
									if (window.history.state && window.history.state.idx > 0) {
										navigate(-1)
									} else {
										navigate('/', { replace: true })
									}
								}}
							>
								<ArrowLeft />
							</IconButton>
						</Box>
					)}
					<Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
						Regard&BeautyHall
					</Typography>
					{false && userQuery.data?.body.username && (
						<Typography
							variant='body1'
							component='div'
							sx={{ marginRight: '10px' }}
						>
							{userQuery.data?.body.username}
						</Typography>
					)}
					{!userQuery.data?.ok ? (
						<Button
							color='inherit'
							onClick={() => navigate('login', { replace: true })}
						>
							Войти
						</Button>
					) : (
						// <Button color='inherit' onClick={() => navigate('logout', {replace: true})}>
						//   Logout
						// </Button>
						<></>
					)}
				</Toolbar>
			</AppBar>
		</Box>
	)
}

export default StyledAppBar
