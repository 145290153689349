import { ApiResponse, User, UserRegistrationData } from 'types'
import { sendAuthorizedRequest } from 'api/jwtApi'
import { BASE_URL } from 'api/config'

export async function registerUser(
	registrationData: UserRegistrationData
): Promise<ApiResponse<Array<User>>> {
	let user_registration_url = BASE_URL + `accounts/users/`

	let result = await fetch(user_registration_url, {
		method: 'POST',
		body: JSON.stringify(registrationData),
		headers: {
			'Content-Type': 'application/json',
		},
	})

	return {
		status_code: result.status,
		ok: result.ok,
		body: await result.json(),
	}
}

export async function updateProfile(
	userObject: object
): Promise<ApiResponse<object>> {
	let user_endpoint_url = BASE_URL + `accounts/users/self/`

	let fetch_options = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(userObject),
	}

	let result = await sendAuthorizedRequest(user_endpoint_url, fetch_options)

	return {
		status_code: result.status,
		ok: result.ok,
		body: await result.json(),
	}
}

export async function getSelfData(): Promise<ApiResponse<User>> {
	let user_self_endpoint_url = BASE_URL + `accounts/users/self/`

	let result = await sendAuthorizedRequest(
		user_self_endpoint_url,
		{ headers: undefined },
		false
	)

	return {
		status_code: result.status,
		ok: result.ok,
		body: await result.json(),
	}
}

export async function getNewEmailVerificationToken(): Promise<
	ApiResponse<object>
> {
	let endpoint_url = BASE_URL + `accounts/users/email_activation_token/`

	let fetch_options = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	}

	let result = await sendAuthorizedRequest(endpoint_url, fetch_options)

	return {
		status_code: result.status,
		ok: result.ok,
		body: {},
	}
}

export async function changePassword(
	passwordChangeObject: object
): Promise<ApiResponse<object>> {
	let endpoint_url = BASE_URL + `accounts/users/self/change_password/`

	let fetch_options = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(passwordChangeObject),
	}

	let result = await sendAuthorizedRequest(endpoint_url, fetch_options)

	return {
		status_code: result.status,
		ok: result.ok,
		body: await result.json(),
	}
}

export async function resetPassword(
	resetPasswordObject: any
): Promise<ApiResponse<object>> {
	let endpoint_url = BASE_URL + `accounts/users/reset_password/`

	let fetch_options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(resetPasswordObject),
	}

	let result = await fetch(endpoint_url, fetch_options)

	let result_body = {}

	try {
		result_body = await result.json()
	} catch {
		result_body = {}
	}

	return {
		status_code: result.status,
		ok: result.ok,
		body: result.json(),
	}
}

export async function generatePasswordResetToken(
	email: string
): Promise<ApiResponse<object>> {
	let endpoint_url =
		BASE_URL + `accounts/users/reset_password_token/?email=${email}`

	let fetch_options = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	}

	let result = await fetch(endpoint_url, fetch_options)

	return {
		status_code: result.status,
		ok: result.ok,
		body: {},
	}
}

export async function checkPasswordResetToken(
	email: string,
	confirmation_token: string
): Promise<ApiResponse<object>> {
	let endpoint_url = BASE_URL + `accounts/users/check_reset_password_token/`

	let fetch_options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			email: email,
			confirmation_token: confirmation_token,
		}),
	}

	let result = await fetch(endpoint_url, fetch_options)

	return {
		status_code: result.status,
		ok: result.ok,
		body: {},
	}
}

export async function validateSensitiveUserData(
	email: string,
	username: string,
	password: string
): Promise<ApiResponse<object>> {
	let endpoint_url = BASE_URL + `accounts/users/validate_sensitive_user_data/`

	let fetch_options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			email: email,
			username: username,
			password: password,
		}),
	}

	let result = await fetch(endpoint_url, fetch_options)

	let result_body = {}

	try {
		result_body = await result.json()
	} catch { }

	return {
		status_code: result.status,
		ok: result.ok,
		body: result_body
	}
}
