import React, { useState } from 'react'
import { Divider, TextField, Stack, Typography, Box } from '@mui/material'
import { useSnackbar } from 'notistack'
import { LoadingButton } from '@mui/lab'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import PhoneNumberTextField from 'components/PhoneNumberTextField'
import {
	getNewEmailVerificationToken,
	getSelfData,
	updateProfile,
} from 'api/userApi'
import { User } from 'types'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ErrorIcon from '@mui/icons-material/Error'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

function Profile() {
	const { enqueueSnackbar } = useSnackbar()
	const queryClient = useQueryClient()

	const [user, setUser] = useState<Omit<User, 'id'>>({
		first_name: '',
		last_name: '',
		patronymic: '',
		email: '',
		phone_number: '',
		specialization: '',
		additional_information: '',
		date_of_birth: null,
		email_activated: false,
		username: '',
	})

	const handleClientChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setUser((prev) => ({
			...prev,
			[event.target.name]: event.target.value,
		}))
	}

	const handlePhoneNumberChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setUser((prev) => ({
			...prev,
			phone_number: event.target.value,
		}))
	}

	const validateData = () => {
		if (!user) throw 'Укажите номер телефона.'

		if (user.phone_number.replaceAll(/\D/g, '').length < 11)
			throw 'Номер телефона указан неверно.'

		if (user.date_of_birth && !user.date_of_birth.isValid())
			throw 'Дата рождения указана неверно'
	}

	const handleChangingProfile = async () => {
		let userObject = {
			...user,
		} as any

		validateData()

		let date_of_birth = null

		if (user.date_of_birth?.isValid()) {
			date_of_birth = user.date_of_birth.format('YYYY-MM-DD')
		}

		userObject.date_of_birth = date_of_birth

		userObject.phone_number = userObject.phone_number?.replaceAll(/\D/g, '')
		let result = await updateProfile(userObject)

		if (!result.ok) {
			let error = result.body as any

			for (let key in error) {
				error[key] = error[key].map((x: string) => {
					let errorString = `${key}: ${x}`
						.replaceAll('user', 'пользователь')
						.replaceAll('patronymic', 'Отчество')
						.replaceAll('last_name', 'Фамилия')
						.replaceAll('first_name', 'Имя')
						.replaceAll('phone number', 'номер телефона')
						.replaceAll('phone_number', 'Номер телефона')

					return errorString
				})
			}

			throw Object.values(error)[0]
		}
	}

	const userQuery = useQuery('user', getSelfData, {
		onSuccess: (data: any) => {
			setUser({
				...data.body,
				date_of_birth: data.body.date_of_birth
					? dayjs(data.body.date_of_birth)
					: null,
			})
		},
		refetchOnMount: true,
		refetchOnWindowFocus: false,
	})

	const emailVerificationTokenQuery = useQuery(
		'emailVerificationToken',
		getNewEmailVerificationToken,
		{
			onSuccess: (data: any) => {
				enqueueSnackbar('Письмо успешно отправлено', { variant: 'success' })
			},
			enabled: false,
			refetchOnWindowFocus: false,
		}
	)

	const clientMutation = useMutation(handleChangingProfile, {
		onSuccess: () => {
			queryClient.invalidateQueries('user')
			enqueueSnackbar('Данные изменены', { variant: 'success' })
		},
		onError: (error: string) => {
			enqueueSnackbar(error, { variant: 'error' })
		},
	})

	return (
		<>
			<Stack
				spacing={2}
				maxWidth={500}
				paddingX='20px'
				m={'0 auto'}
				my={'20px'}
			>
				<Typography
					style={{
						fontSize: 'x-large',
					}}
				>
					Профиль
				</Typography>
				<Divider></Divider>
				<Box>
					{user.email_activated === false && userQuery.data && (
						<Box>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<ErrorIcon fontSize='medium' color='error'></ErrorIcon>
								<Typography variant={'body2'} ml={1}>
									Ваша почта не подтверждена. Чтобы разблокировать возможность
									бронирования подтвердите почту.
								</Typography>
							</Box>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<Typography variant={'caption'}>
									Не пришло письмо с подтверждением?
								</Typography>
								<LoadingButton
									size='small'
									loading={emailVerificationTokenQuery.isLoading}
									onClick={() => emailVerificationTokenQuery.refetch()}
								>
									Отправить повторно
								</LoadingButton>
							</Box>
						</Box>
					)}
				</Box>
				<Box
					sx={{
						display: 'flex',
						width: '100%',
						columnGap: '10px',
						justifyContent: 'space-between',
					}}
				>
					<TextField
						label='Имя'
						required
						value={user.first_name}
						name='first_name'
						onChange={handleClientChange}
					/>
					<TextField
						label='Фамилия'
						required
						name='last_name'
						value={user.last_name}
						onChange={handleClientChange}
					/>
				</Box>
				<TextField
					label='Отчество'
					name='patronymic'
					value={user.patronymic}
					onChange={handleClientChange}
				/>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DatePicker
						views={['month', 'day']}
						inputFormat='DD/MM'
						label='Дата рождения'
						value={user.date_of_birth}
						onChange={(value: any) =>
							setUser((prev) => ({ ...prev, date_of_birth: value }))
						}
						renderInput={(params: any) => <TextField {...params} />}
					/>
				</LocalizationProvider>

				<PhoneNumberTextField
					onChange={handlePhoneNumberChange}
					value={user.phone_number}
				/>
				<TextField
					label='Email'
					required
					name='email'
					value={user.email}
					onChange={handleClientChange}
				/>
				<TextField
					label='Логин'
					required
					disabled
					name='username'
					value={user.username}
					onChange={handleClientChange}
				/>
				<Link to={'/profile/change_password'} style={{ width: 'fit-content' }}>
					<Typography sx={{ width: 'fit-content' }}>Изменить пароль</Typography>
				</Link>
				<TextField
					label='Специализация'
					name='specialization'
					value={user.specialization}
					onChange={handleClientChange}
				/>
				<LoadingButton
					loading={clientMutation.isLoading}
					fullWidth
					variant='contained'
					onClick={() => clientMutation.mutate()}
				>
					Сохранить
				</LoadingButton>
			</Stack>
		</>
	)
}

export default Profile
