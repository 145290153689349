import React from 'react'
import { Typography } from '@mui/material'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { type Dayjs } from 'dayjs'
import { Reservation } from 'types'
import { Button } from '@mui/material'
import { calculateDuration } from 'utils/reservationUtils'

import { SxProps } from '@mui/material'

const CardWrapper = styled(Button)`
  && {
    margin-top: ${(props: { margintop: string; backgroundColor?: string }) =>
		props.margintop || '0px'};
    border-radius: 6px;
    justify-content: flex-start;
    align-items: flex-start;
    text-transform: none;
    color: inherit;
    display: flex;
    text-align: left;
    flex-direction: column;
    padding-left: 10px;
    max-width: 400px;
    width: 100%;
    border-color: lightgray;
    background-color: ${(props) => props.backgroundColor || 'white'};
    :hover {
      background-color: #e3f2fd;
    }
  }
`

const formatDate = (date: Dayjs): string => {
	if (date) {
		return dayjs(date).format('DD/MM/YYYY')
	} else {
		return '---'
	}
}

const ReservationListItem = (props: {
	margintop: string
	onClick?: any
	reservation: Reservation
}) => {
	return (
		<CardWrapper
			margintop={props.margintop}
			onClick={props.onClick}
			variant='outlined'
		>
			<Typography>
				{`${dayjs(props.reservation.reservation_start)
					.tz('Asia/Yekaterinburg')
					.format('HH:mm')} - ${dayjs(props.reservation.reservation_end).tz("Asia/Yekaterinburg").format(
						'HH:mm'
					)}`}
			</Typography>
			<Typography>{`${props.reservation.workplace.name}`}</Typography>
		</CardWrapper>
	)
}

export default ReservationListItem
