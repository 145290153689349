import React, { useState } from 'react'
import {
	Drawer as MuiDrawer,
	ListItemButton,
	ListItemText,
	List,
	Collapse,
	Box,
	SwipeableDrawer,
	ListItemIcon,
} from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import CardMembershipIcon from '@mui/icons-material/CardMembership'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import styled from 'styled-components'
import { useNavigate } from 'react-router'

const StyledDrawer = styled(SwipeableDrawer)`
  && {
    width: ${(props: { width: string }) => props.width};
    & .MuiDrawer-paper {
      width: ${(props) => props.width};
      box-sizing: border-box;
    }
  }
`

const DrawerMenu = (props: {
	width: string
	isOpen: boolean
	onOpen: any
	onClose: any
}) => {
	const [openSubscriptions, setOpenSubscirpotions] =
		React.useState<boolean>(false)
	const [openReservations, setOpenReservations] = useState<boolean>(false)

	const navigate = useNavigate()

	const handleOpenSubscriptions = () => {
		setOpenSubscirpotions(!openSubscriptions)
	}

	const handleOpenReservations = () => {
		setOpenReservations(!openReservations)
	}

	return (
		<StyledDrawer
			anchor='left'
			width={props.width}
			open={props.isOpen}
			onOpen={props.onOpen}
			onClose={props.onClose}
		>
			<List sx={{ height: '100%' }}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: 'column',
						height: '100%',
					}}
				>
					<Box>
						<ListItemButton
							onClick={() => {
								navigate('/profile/self', { replace: true })
								props.onClose()
							}}
						>
							<ListItemIcon>
								<PersonIcon></PersonIcon>
							</ListItemIcon>

							<ListItemText primary='Профиль' />
						</ListItemButton>
						<ListItemButton
							onClick={() => {
								navigate('/reservation/calendar', { replace: true })
								props.onClose()
							}}
						>
							<ListItemIcon>
								<ReceiptLongIcon></ReceiptLongIcon>
							</ListItemIcon>
							<ListItemText primary='Записи' />
						</ListItemButton>
						<ListItemButton
							onClick={() => {
								navigate('/subscriptions', { replace: true })
								props.onClose()
							}}
						>
							<ListItemIcon>
								<CardMembershipIcon></CardMembershipIcon>
							</ListItemIcon>
							<ListItemText primary='Абонементы' />
						</ListItemButton>
					</Box>
					<Box>
						<ListItemButton
							onClick={() => {
								navigate('/logout', { replace: true })
								props.onClose()
							}}
						>
							<ListItemText primary='Выйти' />
						</ListItemButton>
					</Box>
				</Box>
			</List>
		</StyledDrawer>
	)
}

export default DrawerMenu
