import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { createGlobalStyle } from 'styled-components'
import {BrowserRouter} from 'react-router-dom'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'


const GlobalStyle = createGlobalStyle`
	* {
		margin: 0;
		padding: 0;
	}
`

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(
  <React.StrictMode>
		<BrowserRouter>
		<GlobalStyle/>
    <App />
		</BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
