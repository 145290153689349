import React from 'react'
import { TimePicker } from '@mui/x-date-pickers'
import { TextField } from '@mui/material'
import dayjs, { type Dayjs } from 'dayjs'

const StyledTimePicker = (props: {
  label?: string
  value: Dayjs | null
  minValue?: Dayjs
  onChange: any
}) => {
  return (
    <TimePicker
      label={props.label || ''}
      value={props.value}
      onChange={(value) => props.onChange(value)}
      ampm={false}
      renderInput={(params) => <TextField {...params} />}
      shouldDisableTime={(timeValue, clockType) => {
        if (clockType === 'minutes' && timeValue % 30) {
          return true
        }

        return false
      }}
      minTime={props.minValue || null}
			maxTime={dayjs().hour(22).minute(0).second(0).millisecond(0)}
    />
  )
}

export default StyledTimePicker

