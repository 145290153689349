import {
	Stack,
	Step,
	StepLabel,
	Stepper,
	TextField,
	Typography,
} from '@mui/material'
import { Box, Container } from '@mui/system'
import LoadingButton from '@mui/lab/LoadingButton'
import { useState } from 'react'
import { UserRegistrationData } from 'types'
import { registerUser, validateSensitiveUserData } from 'api/userApi'
import { useMutation, useQuery } from 'react-query'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import PhoneNumberTextField from 'components/PhoneNumberTextField'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const steps = ['1', '2']

export const Registration = () => {
	const [userRegistrationData, setUserRegistrationData] =
		useState<UserRegistrationData>({
			password: '',
			username: '',
			email: '',
			first_name: '',
			last_name: '',
			patronymic: '',
			phone_number: '',
			specialization: '',
			date_of_birth: null,
		})

	const [activeStep, setActiveStep] = useState<number>(0)

	const [repeatPassword, setRepeatPassword] = useState<string>('')

	const { enqueueSnackbar } = useSnackbar()

	const navigate = useNavigate()

	const handleRepeatPasswordChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRepeatPassword(event.target.value)
	}

	const userRegistrationDataChange = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		setUserRegistrationData((prev) => ({
			...prev,
			[event.target.name]: event.target.value,
		}))
	}

	const validateSensetiveRegistrationData = () => {
		if (repeatPassword !== userRegistrationData.password)
			throw 'Пароли не совпадают'
	}

	const validateRegistrationData = () => {
		if (userRegistrationData.phone_number.replaceAll(/\D/g, '').length < 11)
			throw 'Номер телефона указан неверно.'

		if (
			userRegistrationData.date_of_birth &&
			!userRegistrationData.date_of_birth.isValid()
		)
			throw 'Дата рождения указана неверно'
	}

	const handleUserRegistration = async () => {
		validateRegistrationData()

		let userRegistrationDataObject = {
			...userRegistrationData,
		} as any

		userRegistrationDataObject.phone_number =
			userRegistrationData.phone_number?.replace(/[^0-9]/g, '')

		if (userRegistrationData.date_of_birth) {
			userRegistrationDataObject.date_of_birth =
				userRegistrationData.date_of_birth.format('YYYY-MM-DD')
		}

		let result = null

		try {
			result = await registerUser(userRegistrationDataObject)
		} catch {
			throw 'Network error'
		}

		if (!result.ok) {
			let error = result.body as any

			for (let key in error) {
				if (!Array.isArray(error[key])) continue

				error[key] = error[key].map((x: string) => {
					let errorString = `${key}: ${x}`
						.replaceAll('password', 'пароль')
						.replaceAll('username', 'логин')
						.replaceAll('email', 'Email')
						.replaceAll('user', 'пользователь')

					return errorString
				})
			}

			if (Object.values(error).length > 0) throw Object.values(error)[0]
		}
	}

	const handleSensetiveUserDataValidation = async () => {
		validateSensetiveRegistrationData()

		let result = await validateSensitiveUserData(
			userRegistrationData.email,
			userRegistrationData.username,
			userRegistrationData.password
		)

		if (!result.ok) {
			let error = result.body as any

			for (let key in error) {
				if (!Array.isArray(error[key])) continue

				error[key] = error[key].map((x: string) => {
					let errorString = `${key}: ${x}`
						.replaceAll('password', 'пароль')
						.replaceAll('username', 'логин')
						.replaceAll('email', 'Email')
						.replaceAll('user', 'пользователь')

					return errorString
				})
			}

			if (Object.values(error).length > 0) throw Object.values(error)[0]
			else throw 'Ошибка'
		}
	}

	const userSensitiveDataValidationQuery = useQuery(
		'sensetiveUserData',
		handleSensetiveUserDataValidation,
		{
			onSuccess: () => {
				setActiveStep(activeStep + 1)
			},
			onError: (error: string) => {
				enqueueSnackbar(error, { variant: 'error' })
			},
			enabled: false,
			retry: false,
		}
	)

	const userRegistrationMutation = useMutation(handleUserRegistration, {
		onSuccess: () => {
			enqueueSnackbar('Успешная регистрация', { variant: 'success' })
			setTimeout(() => navigate('/login'), 2000)
		},
		onError: (error: string) => {
			enqueueSnackbar(error, { variant: 'error' })
		},
	})

	const handleSubmitButtonClick = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		e.stopPropagation()

		if (activeStep === 0) {
			userSensitiveDataValidationQuery.refetch()
		} else if (activeStep === 1) {
			userRegistrationMutation.mutate()
		}
	}

	return (
		<Box>
			<Container
				maxWidth='sm'
				sx={{
					height: '100%',
					paddingTop: '10%',
				}}
			>
				<Stack
					spacing={1}
					sx={{
						height: 'fit-content',
						width: '100%',
					}}
					component='form'
					onSubmit={handleSubmitButtonClick}
				>
					<Typography variant='h5'>Регистрация</Typography>
					<Stepper activeStep={activeStep}>
						{steps.map((label, index) => {
							return (
								<Step key={label}>
									<StepLabel></StepLabel>
								</Step>
							)
						})}
					</Stepper>
					{activeStep === 0 && [
						<TextField
							required
							label='Логин'
							name='username'
							onChange={userRegistrationDataChange}
							fullWidth
							value={userRegistrationData.username}
						/>,
						<TextField
							required
							label='Email'
							name='email'
							onChange={userRegistrationDataChange}
							fullWidth
							value={userRegistrationData.email}
						/>,
						<TextField
							required
							label='Пароль'
							name='password'
							type='password'
							onChange={userRegistrationDataChange}
							fullWidth
							value={userRegistrationData.password}
						/>,
						<TextField
							required
							label='Пароль (повторно)'
							fullWidth
							type='password'
							onChange={handleRepeatPasswordChange}
							value={repeatPassword}
						/>,
					]}
					{activeStep == 1 && [
						<Box
							sx={{
								display: 'flex',
								width: '100%',
								columnGap: '10px',
								justifyContent: 'space-between',
							}}
						>
							<TextField
								label='Имя'
								required
								value={userRegistrationData.first_name}
								name='first_name'
								onChange={userRegistrationDataChange}
							/>
							<TextField
								label='Фамилия'
								required
								name='last_name'
								value={userRegistrationData.last_name}
								onChange={userRegistrationDataChange}
							/>
						</Box>,
						<TextField
							label='Отчество'
							name='patronymic'
							value={userRegistrationData.patronymic}
							onChange={userRegistrationDataChange}
						/>,
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								views={['month', 'day']}
								inputFormat='DD/MM'
								label='Дата рождения'
								value={userRegistrationData.date_of_birth}
								onChange={(value: any) =>
									setUserRegistrationData((prev) => ({
										...prev,
										date_of_birth: value,
									}))
								}
								renderInput={(params: any) => <TextField {...params} />}
							/>
						</LocalizationProvider>,

						<PhoneNumberTextField
							onChange={userRegistrationDataChange}
							value={userRegistrationData.phone_number}
						/>,
						<TextField
							label='Специализация'
							name='specialization'
							value={userRegistrationData.specialization}
							onChange={userRegistrationDataChange}
						/>,
					]}
					<LoadingButton
						variant='outlined'
						fullWidth
						type='submit'
						loading={userRegistrationMutation.isLoading}
					>
						{activeStep == 1 ? 'Зарегистрироваться' : 'Далее'}
					</LoadingButton>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<Typography>Уже есть аккаунт?</Typography>
						<Link to='/login'>
							<Typography>Войти</Typography>
						</Link>
					</Box>
				</Stack>
			</Container>
		</Box>
	)
}

export default Registration
