import React, { useState, useEffect } from 'react'
import { TextField, MenuItem, Box } from '@mui/material'
import styled from 'styled-components'
import { WorkplaceConfigEntry } from 'types'
import { useWorkplaces } from 'hooks/useWorkplaces'

const StyledTextField = styled(TextField)`
  && {
    width: ${(props: { width: string }) => props.width};
  }
`

export const WorkplaceConfigEntrySelector: React.FC<{
  width?: string
  fullWidth?: boolean
  selectedWorkplace: number | null
  handleSelectedWorkplaceChange: any
  disabled?: boolean
  workplaceConfigEntries: Array<WorkplaceConfigEntry>
}> = (props) => {
  const workplacesQuery = useWorkplaces()

  if (workplacesQuery.data?.body === undefined) return null

  return (
    <Box>
      {!workplacesQuery.isLoading && (
        <StyledTextField
          label='Тип рабочего места'
          select
          width={props.width || ''}
          fullWidth={props.fullWidth || false}
          value={props.selectedWorkplace || ''}
          onChange={(event) =>
            props.handleSelectedWorkplaceChange(event.target.value)
          }
          disabled={props.disabled || false}
        >
          {props.workplaceConfigEntries.filter(x => x.amount > 0).map((workplaceConfigEntry) => {
            return (
              <MenuItem
                value={workplaceConfigEntry.workplace}
                key={workplaceConfigEntry.id}
              >
                {' '}
                {
                  workplacesQuery.data!.body.find(
                    (workplace) => workplace.id == workplaceConfigEntry.workplace
                  )!.name
                }{' '}
              </MenuItem>
            )
          })}
        </StyledTextField>
      )}
    </Box>
  )
}

export default WorkplaceConfigEntrySelector

